export function getElementOrThrow<T extends HTMLElement>(selector: string,root:Document|HTMLElement = document): T {
  const element = root.querySelector<T>(selector);
  if (!element) {
    throw new Error(`Could not find element with selector: ${selector}`);
  }
  return element;
}

export function isRtl() {
  const computedStyle = getComputedStyle(document.body);
  return computedStyle.direction === 'rtl';
}

export function dynamicHeightTranslation(){
  const translatableElements = document.querySelectorAll<HTMLElement>('[data-dynamic-height]');
  translatableElements.forEach(translatable => {
    const dependent = translatable.getAttribute('data-dynamic-height');
    if(dependent.trim() === '')return;
    try {
      const dependentElement = getElementOrThrow(dependent);
      const dependentHeight = dependentElement.offsetHeight;
      translatable.style.transform = `translateY(${dependentHeight/2}px)`;
      //listen to height changes
      window.addEventListener('resize',()=>{
        const dependentHeight = dependentElement.offsetHeight;
        translatable.style.transform = `translateY(${dependentHeight/2}px)`;
      });
    } catch (e) {
      console.error(e);
    }
  });
}


export function dynamicHeightCalc(){
  const elements = document.querySelectorAll<HTMLElement>('[data-calc-height]');
  elements.forEach(element => {
    const computedHeight = element.offsetHeight;
    element.style.height = `${computedHeight}px`;
  });
}

interface SwipeDetectionOptions {
  threshold?: number;
  allowedTime: number;
}
export interface SwipeDetectionResult {
  distX: number;
  distY: number;
  startX: number;
  startY: number;
  elapsedTime: number;
  directionX: 'left' | 'right';
  directionY: 'up' | 'down';
}
export function swipe_detection(el:Element,callback: (result:SwipeDetectionResult) => void,options:SwipeDetectionOptions = {threshold: 50,allowedTime: 1000}): void {
  const _state = {
    startX:0,
    startY:0,
    distX:0,
    distY:0,
    elapsedTime:0,
    startTime:0,
    threshold:options.threshold || 100,
    allowedTime:options.allowedTime || 300,
  }
    function touchstart(e:TouchEvent){
        const touch = e.changedTouches[0];
        _state.distX = 0;
        _state.distY = 0;
        _state.startX = touch.pageX;
        _state.startY = touch.pageY;
        _state.startTime = new Date().getTime();
    }
    function touchend(e:TouchEvent){
      const touchObj = e.changedTouches[0];
      const elapsedTime = new Date().getTime() - _state.startTime;
      _state.distX = touchObj.pageX - _state.startX;
      _state.distY = touchObj.pageY - _state.startY;

      if(elapsedTime > _state.allowedTime ||
          (Math.abs(_state.distX) < _state.threshold && Math.abs(_state.distY) < _state.threshold)
      ){
        return;
      }
      const directionX = _state.distX > 0 ? 'left' : 'right';
      const directionY = _state.distY > 0 ? 'up' : 'down';

      return callback({..._state,elapsedTime,directionX,directionY});
    }

    el.addEventListener('touchstart',touchstart);
    // el.addEventListener('touchend
  //',touchend
  //);
    el.addEventListener('touchend',touchend);
}

export function setScrollToMiddle(el: HTMLElement){
  el.scrollTop = (el.scrollHeight - el.clientHeight) / 2;
  el.scrollLeft = (el.scrollWidth - el.clientWidth) / 2;
}

export function OnContentLoaded(f:(...args:any[])=>any){
    window.addEventListener('DOMContentLoaded',f);
}