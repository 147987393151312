import {getElementOrThrow, isRtl, setScrollToMiddle} from "./utils";

enum EngagementMode {
    Touch,
    Mouse,
}

class MovingBox{
    private root = document.documentElement;
    private boxContent: HTMLElement;
    private lastPositionPercentage = { x: 50, y: 50 };
    private engagementMode: EngagementMode;
    private gridItems: HTMLElement[];
    private freezeMode = false;

    constructor(private readonly boxView:HTMLElement) {
        this.boxContent =  getElementOrThrow<HTMLDivElement>('[data-dynamic-moving-box="content"]',this.boxView);
        this.engagementMode = this.isTouchDevice() ? EngagementMode.Touch : EngagementMode.Mouse;
        this.boxView.addEventListener('mousemove', this.setMouseMode.bind(this));
        window.addEventListener("resize", this.translateAndSetPosition.bind(this));
        window.addEventListener("load", this.translateAndSetPosition.bind(this));
        window.addEventListener('touchstart', this.setTouchMode.bind(this));
        this.init_grid_items();
        this.setBoxDirection();
        if(this.engagementMode === EngagementMode.Touch){
            setScrollToMiddle(this.boxView);
        }else {
            this.boxContent.style.transform = 'translate(var(--mouse-x),var(--mouse-y))';
        }
    }

    isTouchDevice() {
        return 'ontouchstart' in window || (navigator.maxTouchPoints > 0);
    }
    private setTouchMode(){
        this.engagementMode = EngagementMode.Touch;
        this.boxContent.style.transform = '';
    }
    private setMouseMode(e: MouseEvent){
        if(this.engagementMode !== EngagementMode.Mouse){
            this.engagementMode = EngagementMode.Mouse;
            this.boxContent.style.transform = 'translate(var(--mouse-x),var(--mouse-y))';
        }
        if(this.freezeMode) return;
        this.setMousePosition(e);
    }

    private getRanges() {
        const boxSize = {
            w: this.boxContent.offsetWidth,
            h: this.boxContent.offsetHeight
        }
        const wrapperSize = {
            w: this.boxView.offsetWidth,
            h: this.boxView.offsetHeight
        }

        const range_x = {
            max: 0,
            min:  wrapperSize.w - boxSize.w
        }
        const range_y = {
            max: 0,
            min: wrapperSize.h - boxSize.h
        }
        return {
            range_x,
            range_y
        }
    }

    private updateLastPosPercentage(clientX:number, clientY:number) {
        const boundingRect = this.boxView.getBoundingClientRect();
        let x = ((clientX - boundingRect.left) / boundingRect.width) * 100;
        let y = ((clientY - boundingRect.top) / boundingRect.height) * 100;
        this.setLastPositionPercentage(x,y);
    }

    private setMousePosition({clientX, clientY,unit="px"}:{clientX:number, clientY:number,unit?:"px"|"%"}) {
        this.updateLastPosPercentage(clientX, clientY);
        const {x_px,y_px} = this.translatePercentageToPixels();
        this.setCssVariables(x_px, y_px, unit);
    }
    private setCssVariables(x:number, y:number, unit="px") {
        this.root.style.setProperty("--mouse-x", x + unit);
        this.root.style.setProperty("--mouse-y", y + unit);
    }

    private translatePercentageToPixels(x:number =this.lastPositionPercentage.x, y:number = this.lastPositionPercentage.y) {
        //match the percentage to the range of the wrapper
        const {range_x, range_y} = this.getRanges();
        const x_px =  (x * (range_x.min + range_x.max) / 100);
        const y_px =  (y * (range_y.min + range_y.max) / 100);
        return {x_px, y_px};
    }

    private translateAndSetPosition(){
        const {x_px,y_px} =this.translatePercentageToPixels();
        this.setCssVariables(x_px, y_px);
    }

    private setBoxDirection(){
        this.boxView.style.direction = "ltr";
        if(isRtl()) {
            this.boxContent.style.direction = "rtl";
        }
    }

    private init_grid_items(){
        const press_events = ['click', 'keypress'];
        this.gridItems = Array.from(this.boxContent.querySelectorAll<HTMLElement>(".grid-item"));
        this.gridItems.forEach(item => {
            press_events.forEach(event => {
                item.addEventListener(event,(e)=>{
                    e.preventDefault();
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    this.on_grid_item_click(item)
                });
            });
            item.addEventListener('mousedown', (e)=>e.preventDefault());
            item.addEventListener("focus",this.on_grid_item_focus.bind(this));
        });
    }

    private on_grid_item_focus(e:FocusEvent){
        e.preventDefault();
        const target = e.target as HTMLElement;

       const x_px = target.offsetLeft + (target.offsetWidth / 2);
       const y_px = target.offsetTop + (target.offsetHeight / 2);

       const parent_width = this.boxContent.offsetWidth;
       const parent_height = this.boxContent.offsetHeight;

       const x = (x_px / parent_width) * 100;
       const y = (y_px / parent_height) * 100;

        this.setLastPositionPercentage(x,y);
        this.translateAndSetPosition();
    }
    private setLastPositionPercentage(x:number, y:number){
        if(x <0) x = 0;
        if(y <0) y = 0;
        if(x >100) x = 100;
        if(y >100) y = 100;
        this.lastPositionPercentage = {x, y};
    }

    private static set_clone_style(clone:HTMLElement, item:HTMLElement){
        const itemDomRect = item.getBoundingClientRect();
        clone.style.position = "fixed";
        clone.style.top = itemDomRect.top + "px";
        clone.style.left = itemDomRect.left + "px";
        clone.style.width = itemDomRect.width + "px";
        clone.style.height = itemDomRect.height + "px";
        clone.style.backgroundSize = "cover";
        clone.style.backgroundPosition = "center";
        clone.style.zIndex = "10000000";
        clone.style.transition = "all 0.5s";
        clone.style.backgroundColor = "rgba(0,0,0,0.75)";
        clone.classList.add("expanding");
    }

    private static set_clone_animation(clone:HTMLElement){
        return clone.animate([
            {
                transformOrigin: "top left",
                transform: "none"
            },
            {
                transformOrigin: "top left",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
            },
        ], {
            duration: 500,
            easing: "ease-in-out",
            fill: "forwards"
        });
    }

    private  set_clone_animation_reverse(clone:HTMLElement){
        const id = clone.getAttribute("data-id");
        const item = this.gridItems.find(item => item.getAttribute("data-id") === id);
        const itemDomRect = item.getBoundingClientRect();
        const top = itemDomRect.top
        const left = itemDomRect.left
        return clone.animate([
            {
                transformOrigin: "top right",
            },
            {
                transformOrigin: "top right",
                top: top + "px",
                left: left + "px",
                width: itemDomRect.width + "px",
                height: itemDomRect.height + "px",
            },
        ], {
            duration: 1000,
            easing: "ease-in-out",
            fill: "forwards"
        });
    }

    private on_grid_item_click(item:HTMLElement){
        if(this.freezeMode) return;
        this.freezeMode = true;
        const clone = item.cloneNode(true) as HTMLElement;
        MovingBox.prepare_clone_for_transition(clone,item);
        document.body.appendChild(clone);

        const animation = MovingBox.set_clone_animation(clone);
        animation.onfinish = ()=> this.on_clone_animation_finish(clone);
    }
    private static prepare_clone_for_transition(clone:HTMLElement, item:HTMLElement){
        MovingBox.set_clone_style(clone, item);
        clone.querySelectorAll(".brand-logo").forEach(e => e.remove());
        if(clone.getAttribute("data-bg-url")){
            clone.style.backgroundImage = "url(" + clone.getAttribute("data-bg-url") + ")";
        }
    }

    private on_clone_animation_finish(clone:HTMLElement){
        this.freezeMode = false;
        if(clone.getAttribute("data-children") === "yes"){
            this.try_open_children(clone);
        }else{
            const url = clone.getAttribute('href') || clone.getAttribute('data-link');
            location.assign(url);
        }
    }
    private try_open_children(clone:HTMLElement){
        const parent_id = clone.getAttribute("data-ID");
        if(!parent_id || parent_id === "") return;
        clone.classList.add("fade-out","duration-2s");
        this.openChildren(parent_id,clone);
        setTimeout(() => clone.classList.add('hidden'), 1900);
    }

    private openChildren(parent_id: string,clone:HTMLElement) {
        const boxView = this.boxView;
        const children = getElementOrThrow<HTMLDivElement>(`[data-parent="${parent_id}"]`);
        children.classList.remove("hidden");
        boxView.classList.add("hidden");
        //add event listener to links to animate
        const links = children.querySelectorAll<HTMLAnchorElement>("a");
        const backButton = getElementOrThrow<HTMLButtonElement>("button.close-brand-children", children);
        backButton.addEventListener("click", () => this.closeChildren.bind(this)(children,clone));
        links.forEach(link=>{
            link.addEventListener("click", (e)=>{
                e.preventDefault();
                this.on_grid_item_click(link);
            })
        })
    }
    private closeChildren(children_container:HTMLElement,clone:HTMLElement){
        //fade in the clone and fade out the children.
        clone.classList.add("fade-in");
        children_container.classList.add("fade-out");
        setTimeout(() => {
            clone.classList.remove("hidden");
            clone.classList.remove("fade-in");
            clone.classList.add('fade-out');
            children_container.classList.add("hidden");
            this.boxView.classList.remove("hidden");
            this.set_clone_animation_reverse(clone).onfinish = ()=>{
                clone.remove();
                this.freezeMode = false;
            }
        }, 10);
    }
}

export function movingBoxes() {
    const boxes = document.querySelectorAll<HTMLElement>("[data-dynamic-moving-box='view']");
    boxes.forEach(box => new MovingBox(box));
}

